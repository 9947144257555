import React from 'react';
import './App.scss';
import Fade from "./Fade";

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        transformTitle: false,
        secondPageRendered: false,
        onCooldown: false,
        hideTitle: false,
        show1: false,
        show2: false,
        show3: false,
        slideIn: false,
        items: ['hello', 'world', 'click', 'me'],
        showComingSoon: false,
        toastOnCooldown: false
    }
  }
  componentDidMount = () => {
    console.log('mounted')
  }

  handleScroll = e => {
    let element = e.target;
    let pageHeight = element.scrollHeight/4
    if(this.state.onCooldown === false){
      if (element.scrollTop > element.clientHeight/4 
        && this.state.transformTitle === false) {
        this.shrink()
      } else if(element.scrollTop < element.clientHeight/4 && this.state.transformTitle === true){
        this.setState({
          secondPageRendered: true
        })
        this.grow()
      }
      if(element.scrollTop > pageHeight*1.2){
        this.setState({
          slideIn: true
        })
      }
    }
  }
  shrink = () => {
    if(this.state.onCooldown === false){
      
      setTimeout(()=> {
        this.setState(()=>{
          return {
            show1: true
          }
        })
      }, 0)
      setTimeout(()=> {
      this.setState((state) => {
        return {
          transformTitle: true,
          onCooldown: true,
          show2: true
        }
      })
      }, 300)
      setTimeout(()=> {
        this.setState(()=>{
          return {
            show3: true
          }
        })
      }, 600)
      setTimeout(()=> {
        this.setState(()=>{
          return {
            onCooldown: false,
            hideTitle: true
          }
        })
      }, 1000)
    }
  }

  grow = () => {
    if(this.state.onCooldown === false){
      console.log('growing')
      this.setState((state) => {
        return {
          transformTitle: false,
          onCooldown: true,
          hideTitle: false
        }
      })
      setTimeout(()=> {
        console.log('grow cooldown off!')
        this.setState(()=>{
          return {
            onCooldown: false
          }
        })
      }, 1000)
    }
  }
  toastComingSoon(){
    if(this.state.onCooldown === false){
      console.log('uuop')
      this.setState({
        onCooldown: true,
        showComingSoon: true
      })
      setTimeout(()=>{
        this.setState({
          onCooldown: false,
          showComingSoon: false
        })
      }, 2500)

    }
  }
  render(){
    return (
      <div className="App">
        <div className="container" onScroll={this.handleScroll}>
          <div className="navbar"
          style={{
            transition: this.state.transformTitle ? 'all 0.25s' : 'all 0.25s',
            color: this.state.transformTitle ? 'black' : 'cadetblue',
            opacity: this.state.transformTitle ? 1 : 0,
            fontSize: '2em',
            textAlign: 'right'
          }}
          >
            <div className="navbar-mask"></div>
            <div className="konjr-logo">Konjr</div>
            <div className="navbar-buttons-container">
              <div className="about-button" onClick={()=>{this.toastComingSoon()}}>about</div>
              <div className="services-button" onClick={()=>{this.toastComingSoon()}}>services</div>
              <div className="blog-button" onClick={()=>{this.toastComingSoon()}}>media</div>
              <div className={`${this.state.showComingSoon ? 'fade-in' : 'fade-out'} coming-soon`}>Coming Soon</div>
            </div>
          </div>
          <section className="main child">
            <div className="headline-banner"><div className="triangle-container">
                <div className="down-arrow bounce"></div>
                <div className="equilateralTriangle">
                  <div className="subtext">Computer vision: evolved</div>
                </div>
              </div>
            </div>
          </section>
          <section className="child white">
            <div className="mask" style={{ animation: `${this.state.show1 && "fadeOutBackground"} 1s forwards` }}></div>
            <div className="circles-super-container">
              <div className="circle-individual-container">
                <Fade show={this.state.show1}>
                  <div className="circle-individual-container">
                    <div className="text-overlay">
                    {/* <div className="large-text">67%</div> */}
                    <div className="icon sensory"></div>
                    <div className="small-text">Multi-sensory Learning</div>
                    </div>
                    <div className="circle">
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="circle-individual-container">
                <Fade show={this.state.show2}>
                  <div className="circle-individual-container">
                    <div className="text-overlay">
                    {/* <div className="large-text">89%</div> */}
                    <div className="icon context"></div>
                    <div className="small-text">Multi-sensory Interaction</div>
                    </div>
                    <div className="circle">
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="circle-individual-container">
                <Fade show={this.state.show3}>
                  <div className="circle-individual-container">
                    <div className="text-overlay">
                    {/* <div className="large-text">56%</div> */}
                    <div className="icon integration"></div>
                    <div className="small-text">Assistive Augmentation</div>
                    </div>
                    <div className="circle">
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className="subtext-container">
              <div className="subtext">
                Enabling the interactive medium between Machine Vision Intelligence and Searchable Reality.
              </div>
            </div>
          </section>
          <section className="child lavender">
            <div className="mask" style={{ animation: `${this.state.show1 && "fadeOutBackground"} 1s forwards` }}></div>
            <div className="top-layer">
              <div 
              style={{ animation: `${this.state.slideIn ? "slideInRight" : "slideOutLeft"} 0.9s forwards` }} 
              className="card text top">
                <div className="text-area">
                  Search the convergent insights of Audio, Video, and IoT
                </div>
              </div>
              <div
              style={{ animation: `${this.state.slideIn ? "slideInLeft" : "slideOutLeft"} 0.9s forwards` }} 
              className="card text bottom">
                <div className="text-area">
                  Build out contextual relationships among data nodes and propagate across any platform 
                </div>
              </div>
            </div>
            <div className="row">
              <div 
              style={{ animation: `${this.state.slideIn ? "slideInLeft" : "slideOutLeft"} 1.4s forwards` }} 
              className="image search"></div>
            </div>
            <div className="row">
              <div style={{ animation: `${this.state.slideIn ? "slideInRight" : "slideOutLeft"} 1.4s forwards` }}  className="image touch"></div>
            </div>
            <div className="footer">
              <div className="container">
                <div className="privacy-policy">Privacy Policy</div>
                <div className="copyright">©2020 Konjr LLC All Rights Reserved</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default App;
